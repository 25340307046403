<template>
	<div
		class="flex-column full-height justify-center bg-white"
	>
		<div class="text-center">
			<v-icon class="size-px-48">mdi-cloud-off-outline</v-icon>
			<p class="mt-10 size-px-16">조회된 상품이 없습니다.</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'empty'
	}
</script>